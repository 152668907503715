import React from 'react';
//import { Link } from 'react-router-dom';
import {
  Container,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import Carousel from 'react-material-ui-carousel';
import MonitorLogoImage from '../assets/monitor_logo.webp';
import MonitorImage from '../assets/monitor.webp';
import MonitorWebImage from '../assets/monitor_web.webp';
import AgricultureImage from '../assets/agriculture.webp';
import CycleImage from '../assets/cycle.webp';
import AttachmentImage from '../assets/attachment.webp';
import DaysImage from '../assets/days.webp';
import Feature1Image from '../assets/feature1.webp';
import Feature2Image from '../assets/feature2.webp';
import theme from '../theme/theme';
import { useTranslation } from 'react-i18next';

const MonitorSection: React.FC = () => {
  const { t } = useTranslation();
  const plans = [
    {
      name: t('monitor.plans.lite.name'),
      price: t('monitor.plans.lite.price'),
      features: [
        t('monitor.plans.lite.feature1'),
        t('monitor.plans.lite.feature2'),
        t('monitor.plans.lite.feature3'),
        t('monitor.plans.lite.feature4'),
        t('monitor.plans.lite.feature5'),
        t('monitor.plans.lite.feature6'),
      ],
    },
    {
      name: t('monitor.plans.standard.name'),
      price: t('monitor.plans.standard.price'),
      features: [
        t('monitor.plans.standard.feature1'),
        t('monitor.plans.standard.feature2'),
        t('monitor.plans.standard.feature3'),
        t('monitor.plans.standard.feature4'),
        t('monitor.plans.standard.feature5'),
        t('monitor.plans.standard.feature6'),
      ],
    },
    {
      name: t('monitor.plans.advanced.name'),
      price: t('monitor.plans.advanced.price'),
      features: [
        t('monitor.plans.advanced.feature1'),
        t('monitor.plans.advanced.feature2'),
        t('monitor.plans.advanced.feature3'),
        t('monitor.plans.advanced.feature4'),
        t('monitor.plans.advanced.feature5'),
        t('monitor.plans.advanced.feature6'),
        t('monitor.plans.advanced.feature7'),
        t('monitor.plans.advanced.feature8'),
        t('monitor.plans.advanced.feature9'),
      ],
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="lg">
        <Typography>
          <CardMedia
            image={MonitorLogoImage}
            title="MonitorLogo"
            style={{
              width: 242,
              height: 50,
              marginTop: '6rem',
              marginBottom: '2rem',
            }}
          />
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Carousel>
              <CardMedia
                image={MonitorWebImage}
                title="Monitor"
                style={{ maxWidth: '100%', height: '500px' }}
              />
              <CardMedia
                image={MonitorImage}
                title="Monitor"
                style={{ maxWidth: '100%', height: '500px' }}
              />
              <CardMedia
                image={CycleImage}
                title="Monitor"
                style={{ maxWidth: '100%', height: '500px' }}
              />
            </Carousel>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h3" component="h2" align="center" gutterBottom>
              {t('monitor.featuresTitle')}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="h4"
              component="h2"
              style={{ marginTop: '1rem', marginBottom: '1rem' }}
            >
              {t('monitor.integratedServiceTitle')}
            </Typography>

            <Typography paragraph>
              {t('monitor.integratedServiceDesc1')}
            </Typography>
            <Typography paragraph>
              {t('monitor.integratedServiceDesc2')}
            </Typography>
            <Typography paragraph>
              {t('monitor.integratedServiceDesc3')}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="h4"
              component="h2"
              style={{ marginTop: '3rem', marginBottom: '1rem' }}
            >
              {t('monitor.noSetupTitle')}
            </Typography>
            <Typography paragraph>{t('monitor.noSetupDesc1')}</Typography>
            <Typography paragraph>{t('monitor.noSetupDesc2')}</Typography>
            <Typography paragraph>{t('monitor.noSetupDesc3')}</Typography>
            <CardMedia
              image={Feature2Image}
              title="Feature"
              style={{ height: 450 }}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="h4"
              component="h2"
              style={{ marginTop: '3rem', marginBottom: '1rem' }}
            >
              {t('monitor.portableTitle')}
            </Typography>
            <Typography paragraph>{t('monitor.portableDesc1')}</Typography>
            <Typography paragraph>{t('monitor.portableDesc2')}</Typography>
            <CardMedia
              image={Feature1Image}
              title="Feature"
              style={{ height: 400 }}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="h4"
              component="h2"
              style={{ marginTop: '3rem', marginBottom: '1rem' }}
            >
              {t('monitor.lightnessTitle')}
            </Typography>
            <Typography paragraph>{t('monitor.lightnessDesc1')}</Typography>
            <Typography paragraph>{t('monitor.lightnessDesc2')}</Typography>
            <Typography paragraph>{t('monitor.lightnessDesc3')}</Typography>
            <CardMedia
              image={AttachmentImage}
              title="Attachment"
              style={{ height: 390 }}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="h4"
              component="h2"
              style={{ marginTop: '3rem', marginBottom: '1rem' }}
            >
              {t('monitor.dateSearchTitle')}
            </Typography>
            <Typography paragraph>{t('monitor.dateSearchDesc1')}</Typography>
            <Typography paragraph>{t('monitor.dateSearchDesc2')}</Typography>
            <CardMedia image={DaysImage} title="Days" style={{ height: 500 }} />
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="h3"
              component="h2"
              align="center"
              gutterBottom
              style={{ marginTop: '3rem', marginBottom: '1rem' }}
            >
              {t('monitor.plansTitle')}
            </Typography>
            <Grid>
              <Grid container spacing={3}>
                {plans.map((plan, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card>
                      <CardContent>
                        <Typography variant="h5" component="h3">
                          {plan.name}
                        </Typography>
                        <Typography variant="h6" color="primary">
                          {plan.price}
                        </Typography>
                        <List>
                          {plan.features.map((feature, idx) => (
                            <ListItem key={idx}>
                              <ListItemText primary={feature} />
                            </ListItem>
                          ))}
                        </List>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="h4"
              component="h2"
              style={{ marginTop: '3rem', marginBottom: '1rem' }}
            >
              {t('monitor.lowPriceTitle')}
            </Typography>
            <Typography paragraph>{t('monitor.lowPriceDesc1')}</Typography>
            <Typography paragraph>{t('monitor.lowPriceDesc2')}</Typography>
            <Typography paragraph>{t('monitor.lowPriceDesc3')}</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="h3"
              component="h2"
              align="center"
              gutterBottom
              style={{ marginTop: '3rem', marginBottom: '1rem' }}
            >
              {t('monitor.useCasesTitle')}
            </Typography>
            <Typography paragraph>{t('monitor.useCasesDesc')}</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <Card>
                  {/*<CardActionArea component={Link} to="/use-case/agriculture">*/}
                  <CardActionArea>
                    <CardMedia
                      image={AgricultureImage}
                      title="Agriculture"
                      style={{ height: 140 }}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="h2">
                        {t('monitor.comingSoon')}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            </Grid>
          </Grid>

          {/*
          <Grid item xs={12}>
            <Typography variant="h3" component="h2" align="center" gutterBottom>
              ドキュメント
            </Typography>
            <Grid>操作方法</Grid>
            <Grid>スペック</Grid>
          </Grid>
          */}
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

export default MonitorSection;
