import React from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Grid,
  List,
  ListItem,
} from '@mui/material';
import HimawariImage from '../assets/building.webp';
import { GoldenRatioImage, accessMapStyle } from '../styles/commonStyles';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const AboutUsSection: React.FC = () => {
  const { t } = useTranslation();
  const gmapSrc =
    i18n.language == 'ja'
      ? 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3241.7396678296273!2d139.333040364336!3d35.6587851301996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60191f4831bd479d%3A0xa60beae49ef36e2d!2sGenpls!5e0!3m2!1sja!2sjp!4v1551849527995'
      : 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3241.739192833425!2d139.33270337713722!3d35.65879683120394!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60191e77fc382ebf%3A0x38a4a8f7b7f38c45!2z44CSMTkyLTAwODUgVG9reW8sIEhhY2hpb2ppLCBOYWthY2jFjSwgOeKIkjEwIOOBsuOBvuOCj-OCiuODk-ODqw!5e0!3m2!1sen!2sjp!4v1707666516993!5m2!1sen!2sjp';
  return (
    <Box mt={4} mb={6}>
      <Typography variant="h3" align="center" gutterBottom>
        {t('nav.aboutus')}
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{t('aboutus.tr1.th')}</TableCell>
            <TableCell>
              <List>
                <ListItem>{t('aboutus.tr1.td')}</ListItem>
              </List>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{t('aboutus.tr2.th')}</TableCell>
            <TableCell>
              <List>
                <ListItem>{t('aboutus.tr2.td')}</ListItem>
              </List>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{t('aboutus.tr3.th')}</TableCell>
            <TableCell>
              <List>
                <ListItem>{t('aboutus.tr3.td.txt1')}</ListItem>
                <ListItem>{t('aboutus.tr3.td.txt2')}</ListItem>
                <ListItem>{t('aboutus.tr3.td.txt3')}</ListItem>
                <ListItem>{t('aboutus.tr3.td.txt4')}</ListItem>
              </List>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper elevation={0}>
            <GoldenRatioImage src={HimawariImage} alt="Himawari building" />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={accessMapStyle}>
            <Box
              component="iframe"
              src={gmapSrc}
              width="100%"
              height="100%"
              style={{ border: 0, position: 'absolute', top: 0, left: 0 }}
              allowFullScreen
              title="Genpls Access Map"
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AboutUsSection;
