import React from 'react';
import { Link } from 'react-router-dom';
import MuiLink from '@mui/material/Link';
import {
  Box,
  Typography,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import MonitorImage from '../assets/monitor.webp';
import MonitorWebImage from '../assets/monitor_web.webp';
import AgricultureImage from '../assets/agriculture.webp';
import SpaceImage from '../assets/space.webp';
import { GoldenRatioImage } from '../styles/commonStyles';
import { useTranslation } from 'react-i18next';

const ServiceSection: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box mt={4} mb={6}>
      <Typography variant="h3" align="center" gutterBottom>
        {t('nav.service')}
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom component="h1">
            {t('service.sec1.ttl')}
          </Typography>
          <Typography variant="subtitle1" gutterBottom component="p">
            {t('service.sec1.txt1')}
          </Typography>
          <List>
            <ListItem>
              <ListItemText
                primary={t('service.sec1.txt2')}
                secondary={t('service.sec1.txt3')}
                secondaryTypographyProps={{ style: { color: '#000000' } }}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={t('service.sec1.txt4')}
                secondary={t('service.sec1.txt5')}
                secondaryTypographyProps={{ style: { color: '#000000' } }}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={t('service.sec1.txt6')}
                secondary={t('service.sec1.txt7')}
                secondaryTypographyProps={{ style: { color: '#000000' } }}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={t('service.sec1.txt8')}
                secondary={t('service.sec1.txt9')}
                secondaryTypographyProps={{ style: { color: '#000000' } }}
              />
            </ListItem>
          </List>
          <Typography variant="body1" component="p">
            {t('service.sec1.txt10')}
          </Typography>
          <Typography variant="body1" component="p">
            <Link to="/monitor">{t('service.sec1.txt11')}</Link>
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={0}>
            <img
              src={MonitorImage}
              alt="Monitor"
              style={{ width: '100%', marginBottom: '1rem' }}
            />
            <img
              src={MonitorWebImage}
              alt="Monitor Web"
              style={{ width: '100%' }}
            />
          </Paper>
        </Grid>
      </Grid>

      <Typography paragraph></Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper elevation={0}>
            <GoldenRatioImage src={SpaceImage} alt="Space" />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom>
            {t('service.sec2.ttl')}
          </Typography>
          <Typography paragraph>{t('service.sec2.txt1')}</Typography>
          <Typography paragraph>{t('service.sec2.txt2')}</Typography>
          <Typography paragraph>{t('service.sec2.txt3')}</Typography>
          <Typography paragraph>{t('service.sec2.txt4')}</Typography>
          <Typography paragraph>
            <MuiLink
              href="https://spacemarket.com/p/iWe0aXKX5dJzRyL8"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('service.sec2.txt5')}
            </MuiLink>
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom>
            {t('service.sec3.ttl')}
          </Typography>
          <Typography paragraph>{t('service.sec3.txt1')}</Typography>
          <Typography paragraph>{t('service.sec3.txt2')}</Typography>
          <Typography paragraph>{t('service.sec3.txt3')}</Typography>
          <Typography paragraph>
            <MuiLink
              href="https://mercari-shops.com/shops/7dVGY2PNs4MENSqRAiJBFC"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('service.sec3.txt4')}
            </MuiLink>
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={0}>
            <GoldenRatioImage src={AgricultureImage} alt="Agriculture" />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ServiceSection;
